import React, { useEffect, useState } from 'react';
import { Polyline, Tooltip, Marker, useMapEvents } from 'react-leaflet';

//import { observer } from 'mobx-react-lite';

import L from 'leaflet';
import * as _ from 'lodash';

import 'react-tooltip/dist/react-tooltip.css';
import 'leaflet/dist/leaflet.css';

import RotatedMarker from './Controls/RotatedMarker';
import ScoringTable from './Controls/ScoringTable';
import AltChart from './Controls/AltChart';

import settingsStore from './Store/settingsStore';
import chartSettingsStore from './Store/chartSettingsStore';
import trackingStore from './Store/trackingStore';
import GliderIcon from './GliderIcon/GliderIcon';

const BLUE = '#4a4a7e';
const RED = '#ff3b30';
// const ORANGE = '#f45501';
const colors = [
  RED,
  BLUE,
  '#ffcc00',
  '#ff9500',
  '#4cd964',
  '#5ac8fa',
  '#7b0000',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#FFAAFF'
];

const POLLING_INTERVAL = 2000;

var lastActiveUsers = 0;

let tailLength = settingsStore.TailLength;
var reloadTimer;

function Tracking({ onPilotsCountChange, trackingType, currentTask }) {
  const [lastPoints, setLastPoints] = useState([]);
  const [lastPointsDate, setLastPointsDate] = useState(0);
  const [lastTailLength, setLastTailLength] = useState(0);
  const [serverID, setServerID] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
      console.log('zoom:' + zoomLevel);
    }
  });
  if (currentTask !== null && serverID != currentTask.serverID) {
    setServerID(currentTask.serverID);
    setLastTailLength(-1);
    settingsStore.deleteAllUsers();
    trackingStore.deleteAllTail();
    trackingStore.setActivePilotID('');
  }

  const loadLink =
    trackingType === 'condor'
      ? 'https://tracking.crc.team/api/condor/getPlanes.php'
      : 'https://tracking.crc.team/api/live/getLive.php';
  var activePilots = [];
  useEffect(() => {
    tailLength = settingsStore.TailLength != lastTailLength ? settingsStore.TailLength : 0;
    setLastTailLength(settingsStore.TailLength);

    fetch(
      loadLink +
        '?serverID=' +
        serverID +
        (tailLength ? '&tailLength=' + tailLength : '') +
        (chartSettingsStore.reloadChartPilot
          ? '&activePilot=' + chartSettingsStore.activeChartPilot + ',' + settingsStore.SelectedTailLength
          : '')
    )
      .then((res) => {
        if (res.status === 200) {
          tailLength = 0;
          chartSettingsStore.reloadChartPilot = false;
          return res.json();
        } else return [];
      })
      .then(
        (points) => {
          if (lastActiveUsers != points.length) {
            onPilotsCountChange(points);
            lastActiveUsers = points.length;
          }
          if (points.length) {
            const ticksByID = _.groupBy(points, 'id');
            _.forEach(ticksByID, (points, pilotId) => {
              activePilots.push(pilotId);
              if (trackingStore.activePilot == pilotId) trackingStore.setActivePilotData(points[0]);
              if (trackingStore.tails[pilotId]) {
                if (points[0].tail.length > 0) {
                  trackingStore.tails[pilotId].length = 0;
                  points[0].tail.forEach((element) => {
                    trackingStore.tails[pilotId].push(element);
                  });
                }
                trackingStore.tails[pilotId].push(points[0]);
                var date = new Date();
                var utcTimeEnd = date.getTime() + date.getTimezoneOffset() * 60000;
                trackingStore.tails[pilotId].forEach(function (item) {
                  var timeStart = new Date(item.time).getTime();
                  var minDiff = (utcTimeEnd - timeStart) / 60 / 1000; //in minutes
                  if (
                    (chartSettingsStore.activeChartPilot != pilotId && minDiff < settingsStore.TailLength) ||
                    (chartSettingsStore.activeChartPilot == pilotId && minDiff < settingsStore.SelectedTailLength)
                  )
                    return;
                  trackingStore.tails[pilotId].shift();
                });
              } else {
                trackingStore.tails[pilotId] = [];
                if (points[0].tail.length > 0) {
                  points[0].tail.forEach((element) => {
                    trackingStore.tails[pilotId].push(element);
                  });
                }

                trackingStore.tails[pilotId].push(points[0]);
                settingsStore.addUserSetting(
                  pilotId,
                  points[0].cn,
                  points[0].userName,
                  colors[Object.keys(settingsStore.userSettings).length % colors.length],
                  points[0].needScoring
                );
              }
              if (pilotId == chartSettingsStore.activeChartPilot) {
                chartSettingsStore.updateChartPointsForActiveGlider(pilotId);
              }
            });

            points = _.sortBy(points, (p) => p.rank);
            setLastPoints(points);
            clearTimeout(reloadTimer);
            //console.log('clearTimeout 1');
            reloadTimer = setTimeout(() => {
              setLastPointsDate(new Date().toISOString());
            }, POLLING_INTERVAL);
          } else {
            setLastPoints([]);
            clearTimeout(reloadTimer);
            //console.log('clearTimeout 2');
            reloadTimer = setTimeout(() => {
              setLastPointsDate(new Date().toISOString());
            }, POLLING_INTERVAL + 5000);
          }
          Object.keys(settingsStore.userSettings).forEach((pilotId) => {
            if (!activePilots.includes(pilotId)) {
              settingsStore.deleteUser(pilotId);
              trackingStore.deleteTail(pilotId);
            }
          });
        },
        (error) => {
          console.log(error);
          if (error) {
            clearTimeout(reloadTimer);
            //console.log('clearTimeout error');
            reloadTimer = setTimeout(() => {
              setLastPointsDate(new Date().toISOString());
            }, POLLING_INTERVAL + 5000);
            return [];
          }
        }
      );
  }, [lastPointsDate, serverID]);

  const GliderTracks = [];
  const GliderIcons = [];
  const GliderIconsCN = [];
  const GliderAltLabels = [];
  const AatLines = [];
  const ProjectedLines = [];

  // PILOT TRACKS
  _.forEach(trackingStore.tails, (points, pilotId) => {
    const lastPoint = points[points.length - 1];
    if (settingsStore.userSettings[lastPoint.id].showGlider == true) {
      const positions = points.map((p) => {
        return [p.lat, p.lon, p.alt];
      });
      const iconColor = settingsStore.userSettings[lastPoint.id].userColor;
      const gliderIcon = GliderIcon(lastPoint.icon, iconColor);

      const gliderCN = new L.divIcon({
        html: `
          <div style="
            display: flex;
            justify-content: center;">
            <b style="
              background-color: #fffffff0;
              position: absolute;
              border: 1px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 14px;
              padding-left: 2px;
              padding-right: 2px;
              top: -40px;
              text-align: center;
            ">${lastPoint.cn}</b>
          </div>
        `,
        iconSize: [10, 10],
        className: 'glider-icon-cn'
      });

      GliderIconsCN.push(
        <Marker
          key={pilotId}
          position={[lastPoint.lat, lastPoint.lon]}
          icon={gliderCN}
          eventHandlers={{
            click: () => {
              chartSettingsStore.setActiveChartGlider(pilotId);
            }
          }}></Marker>
      );
      // const gliderAlt = new L.divIcon({
      //   html: `
      //     <div>
      //       <small style="font-size: 9px">${lastPoint.alt}м</small>
      //     </div>
      //   `,
      //   iconSize: [40, 10],
      //   className: 'glider-alt'
      // });

      const gliderAlt = new L.divIcon({
        html: `
          <div style="
            display: flex;
            justify-content: center;">
            <b style="
              position: absolute;
              font-size: 10px;
              padding-left: 2px;
              padding-right: 2px;
              top: 15px;
              left: 15px;
              text-align: center;
            ">${lastPoint.alt}м</b>
          </div>
        `,
        iconSize: [10, 10],
        className: 'glider-alt'
      });

      zoomLevel > 13 &&
        GliderAltLabels.push(
          <Marker key={pilotId} position={[lastPoint.lat, lastPoint.lon]} icon={gliderAlt}></Marker>
        );

      GliderIcons.push(
        <RotatedMarker
          key={pilotId}
          rotationAngle={+lastPoint.heading}
          position={[lastPoint.lat, lastPoint.lon]}
          icon={gliderIcon}
          eventHandlers={{
            click: () => {
              chartSettingsStore.setActiveChartGlider(pilotId);
            }
          }}>
          <Tooltip>
            <b>{lastPoint.userName}</b> ({lastPoint.plane})<br />
            <b>H: {lastPoint.alt} м</b>
            <br />
            <b>
              Vs: {lastPoint.vario > 0 && `+${lastPoint.vario}`}
              {lastPoint.vario <= 0 && lastPoint.vario} м/с
            </b>
          </Tooltip>
        </RotatedMarker>
      );

      let tailOpacity = settingsStore.userSettings[lastPoint.id].showTail == true ? 1 : 0;

      GliderTracks.push(
        <Polyline
          weight={pilotId == chartSettingsStore.activeChartPilot ? 3 : 2}
          key={pilotId}
          pathOptions={{ color: iconColor, opacity: tailOpacity }}
          positions={[...positions]}
          interactive={false}
        />
      );

      let aatResultOpacity = settingsStore.userSettings[lastPoint.id].showAatResult == true ? 1 : 0;
      const AatPointsCoordinates = lastPoint.aatPoints.map((point) => {
        return [point.lat, point.lon];
      });

      AatLines.push(
        <Polyline
          weight={2}
          key={pilotId}
          pathOptions={{
            color: iconColor,
            dashArray: '3, 3',
            dashOffset: '0',
            opacity: aatResultOpacity
          }}
          positions={AatPointsCoordinates}
          interactive={false}
        />
      );

      let aatProjecionsOpacity = settingsStore.userSettings[lastPoint.id].showProjection == true ? 1 : 0;
      const projLine = [
        [lastPoint.lat, lastPoint.lon],
        [lastPoint.projPoint_lat, lastPoint.projPoint_lon]
      ];
      ProjectedLines.push(
        <Polyline
          weight={2}
          key={pilotId}
          pathOptions={{
            color: iconColor,
            dashArray: '10, 5',
            dashOffset: '0',
            opacity: aatProjecionsOpacity
          }}
          positions={projLine}
          interactive={false}
        />
      );
    }
  });

  return (
    <>
      {GliderIcons}
      {GliderIconsCN}
      {GliderAltLabels}
      {GliderTracks}
      {AatLines}
      {ProjectedLines}
      <ScoringTable points={lastPoints} currentTask={currentTask} />
      <AltChart />
    </>
  );
}

export default Tracking;
