//import { useMapEvent } from 'react-leaflet';

export default function MapMonitor() {
  /*
  const map = useMapEvent({
    moveend: () => {
      //console.log('map moveend:', map.getCenter());
    },
    click: () => {
      //console.log('map click:', map.attributionControl);
    },
    baselayerchange: (e) => {
      //console.log('baselayerchange:');
      //console.log(e.layer.options.attribution);
    }
  });
  */
  return null;
}
