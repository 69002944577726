import settingsStore from '../Store/settingsStore';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function windSpeed(x) {
  return settingsStore.windSpeed == 'ms' ? x.toFixed(1) + ' м/с' : (x * 3.6).toFixed(1) + ' км/ч';
}

export { numberWithCommas, windSpeed };
