import { makeAutoObservable } from 'mobx';
import chartSettingsStore from './chartSettingsStore';

class settingsStore {
  userSettings = [];
  scoringTableSettings = {
    userName: true,
    distance: true,
    speed: true,
    taskSpeed: true,
    alt: true,
    vario: true,
    time: true
  };
  allGlidersVisibility = true;
  allTailsVisibility = true;
  allAatResultsVisibility = false;
  allProjectionVisibility = false;

  SelectedTailLength = 30;
  TailLength = 30;
  windSpeed = 'ms';
  showPenaltyZones = true;
  showWeatherZones = true;
  showPlayersOnly = false;
  showAntenna = false;
  showWeather = false;

  constructor() {
    makeAutoObservable(this);
    if (localStorage.getItem('ScoringTableSettings') !== null)
      this.scoringTableSettings = JSON.parse(localStorage.getItem('ScoringTableSettings'));
    if (localStorage.getItem('SelectedTailLength') !== null)
      this.SelectedTailLength = localStorage.getItem('SelectedTailLength');
    if (localStorage.getItem('TailLength') !== null) this.TailLength = localStorage.getItem('TailLength');
    if (localStorage.getItem('windSpeed') !== null) this.windSpeed = localStorage.getItem('windSpeed');

    if (localStorage.getItem('showPenaltyZones') !== null)
      this.showPenaltyZones = localStorage.getItem('showPenaltyZones');
    if (localStorage.getItem('showWeatherZones') !== null)
      this.showWeatherZones = localStorage.getItem('showWeatherZones');
    if (localStorage.getItem('showPlayersOnly') !== null)
      this.showPlayersOnly = localStorage.getItem('showPlayersOnly');
    if (localStorage.getItem('showAntenna') !== null) this.showAntenna = localStorage.getItem('showAntenna');
    if (localStorage.getItem('showWeather') !== null) this.showWeather = localStorage.getItem('showWeather');

    this.saveScoringTableSettings();
    this.changeSelectedTailLength(this.SelectedTailLength);
    this.changeTailLength(this.TailLength);
    this.changeWindSpeed(this.windSpeed);
    this.changePZ(this.showPenaltyZones);
    this.changeWZ(this.showWeatherZones);
    this.changePlayersOnly(this.showPlayersOnly);
    this.changeShowAntenna(this.showAntenna);
    this.changeShowWeather(this.showWeather);
  }

  addUserSetting(pilotId, cn, userName, color, needScoring) {
    this.userSettings[pilotId] = {
      id: pilotId,
      cn: cn,
      showGlider: this.allGlidersVisibility,
      showTail: this.allTailsVisibility,
      showAatResult: this.allAatResultsVisibility,
      showProjection: this.allProjectionVisibility,
      userName: userName,
      userColor: color,
      needScoring: needScoring
    };
  }
  deleteUser(key) {
    delete this.userSettings[key];
  }
  deleteAllUsers() {
    for (var key in this.userSettings) {
      delete this.userSettings[key];
    }
  }
  saveScoringTableSettings() {
    localStorage.setItem('ScoringTableSettings', JSON.stringify(this.scoringTableSettings));
  }
  loadScoringTableSettings(newSettings) {
    this.scoringTableSettings = newSettings;
  }
  changeScoringTableSettings(key) {
    this.scoringTableSettings[key] = !this.scoringTableSettings[key];
    this.saveScoringTableSettings();
  }
  changeGliderVisibility(key) {
    this.userSettings[key].showGlider = !this.userSettings[key].showGlider;
  }
  changeTailVisibility(key) {
    this.userSettings[key].showTail = !this.userSettings[key].showTail;
  }
  changeAATResVisibility(key) {
    this.userSettings[key].showAatResult = !this.userSettings[key].showAatResult;
  }
  changeProjectionsVisibility(key) {
    this.userSettings[key].showProjection = !this.userSettings[key].showProjection;
  }
  changeSelectedTailLength(value) {
    this.SelectedTailLength = value;
    chartSettingsStore.changeReloadChartPilot(true);
    localStorage.setItem('SelectedTailLength', value);
  }
  changeTailLength(value) {
    this.TailLength = value;
    localStorage.setItem('TailLength', value);
  }
  changeWindSpeed(value) {
    this.windSpeed = value;
    localStorage.setItem('windSpeed', value);
  }
  changePZ(value) {
    this.showPenaltyZones = value === 'false' || value === false ? false : true;
    localStorage.setItem('showPenaltyZones', this.showPenaltyZones);
  }
  changeWZ(value) {
    this.showWeatherZones = value === 'false' || value === false ? false : true;
    localStorage.setItem('showWeatherZones', this.showWeatherZones);
  }
  changePlayersOnly(value) {
    this.showPlayersOnly = value === 'false' || value === false ? false : true;
    localStorage.setItem('showPlayersOnly', this.showPlayersOnly);
  }
  changeShowAntenna(value) {
    this.showAntenna = value === 'false' || value === false ? false : true;
    localStorage.setItem('showAntenna', this.showAntenna);
  }
  changeShowWeather(value) {
    this.showWeather = value === 'false' || value === false ? false : true;
    localStorage.setItem('showWeather', this.showWeather);
  }
  showAllGliders() {
    this.allGlidersVisibility = !this.allGlidersVisibility;
    for (var key in this.userSettings) {
      this.userSettings[key].showGlider = this.allGlidersVisibility;
    }
  }
  showAllTails() {
    this.allTailsVisibility = !this.allTailsVisibility;
    for (var key in this.userSettings) {
      this.userSettings[key].showTail = this.allTailsVisibility;
    }
  }
  showAatTracks() {
    this.allAatResultsVisibility = !this.allAatResultsVisibility;
    for (var key in this.userSettings) {
      this.userSettings[key].showAatResult = this.allAatResultsVisibility;
    }
  }
  showProjections() {
    this.allProjectionVisibility = !this.allProjectionVisibility;
    for (var key in this.userSettings) {
      this.userSettings[key].showProjection = this.allProjectionVisibility;
    }
  }
}

export default new settingsStore();
