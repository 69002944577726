import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import styles from './Task.module.css';
import { windSpeed } from '../helpers/helpers';

function InfoBar(props) {
  const { task, trackingType } = props;
  if (!task) return;
  if (task.raceType == 'FF') {
    return (
      <div className={styles.infoBar}>
        <b>CRC.team</b>
      </div>
    );
  }

  const taskParams = task.parameters;
  const aatHours = Math.floor(task.aatTime / 60);
  const aatMinutes = task.aatTime % 60;
  return (
    <div className={styles.infoBar}>
      <Container fluid>
        <Row>
          {trackingType === 'condor' && (
            <Col className={styles.hideIt}>
              <b>
                <span className={styles.label}>Ландшафт:</span> {taskParams.Landscape}
              </b>
            </Col>
          )}
          {trackingType === 'condor' && (
            <Col className={styles.hideIt}>
              <b>
                <span className={styles.label}>Класс:</span> {taskParams.gliderClass}
              </b>
            </Col>
          )}
          {trackingType === 'live' && (
            <Col>
              <b>
                <span className={styles.label}>Задание: </span>
              </b>
              {task.raceType == 'AAT' && (
                <b>
                  ААТ {aatHours} ч {aatMinutes} м
                </b>
              )}
              {task.raceType == 'RT' && <b>Гонка</b>}
            </Col>
          )}
          <Col className={styles.label}>
            <b>
              <span className={styles.label}>Высота старта: </span>
            </b>
            {trackingType === 'live' && taskParams.start_max_height == 0 && <b>Не ограничена</b>}
            {trackingType === 'live' && taskParams.start_max_height > 0 && <b>{taskParams.start_max_height} м</b>}
            {trackingType === 'condor' && <b>{task.turnPoints[0]?.TPHeight} м</b>}
          </Col>
          <Col className={styles.hideFinishAlt}>
            <b>
              <span className={styles.label}>Высота финиша:</span>{' '}
              {trackingType === 'live'
                ? taskParams.finish_min_height
                : task.turnPoints[task.turnPoints.length - 1]?.TPWidth}{' '}
              м
            </b>
          </Col>
          <Col>
            <b>
              <span className={styles.label}>Дистанция:</span> {task.taskDistance} км
            </b>
          </Col>
          <Col className={styles.hideIt}>
            <b>
              <span className={styles.label}>Tемпература:</span> {task.temperature}°C
            </b>
          </Col>
          <Col>
            <b>
              <span className={styles.label}>Ветер:</span> {task.windDir}° {windSpeed(task.windSpeed)}
            </b>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default observer(InfoBar);
