import React from 'react';
import { Polyline, Marker, LayerGroup, Circle, Polygon, Popup } from 'react-leaflet';
import { observer } from 'mobx-react-lite';
import 'leaflet-semicircle';
import L from 'leaflet';

import SemiCircle from '../Controls/SemiCircle';
import settingsStore from '../Store/settingsStore';
import { windSpeed } from '../helpers/helpers';

import styles from '../styles/Home.module.css';

function Task({ currentTask, trackingType }) {
  if (!currentTask) return;
  const windDir = Math.round(currentTask.windDir);
  const windSpeedTitle = windSpeed(currentTask.windSpeed);
  //  settingsStore.windSpeed == 'ms' ? currentTask.windSpeed + ' м/с' : currentTask.windSpeed * 3.6 + ' км/ч';
  const ppmLabels = [];
  const ppmAreas = [];
  const penaltyZones = [];
  const weatherZones = [];

  currentTask.turnPoints.forEach((point, idx) => {
    let tpName = 'TP' + idx + ' ' + point.TPName;
    if (idx === currentTask.turnPoints.length - 1) tpName = 'Finish';
    if (idx === 0) tpName = 'Start';

    const icon = L.divIcon({
      className: 'turn-point-name', // Set class for CSS styling
      html: `
        <div style="display: flex;justify-content: center;">
          <b style="
            background-color: #ffffff;
            border: 1px solid;
            border-radius: 3px;
            font-weight: bolder;
            font-size: 12px;
            padding-left: 2px;
            padding-right: 2px;
            text-align: center;
            top: -20px;
            position: absolute;
            white-space: nowrap;
          ">${tpName}</b>
        </div>
        `
    });
    ppmLabels.push(
      <Marker key={idx} position={[point.lat, point.lon]} icon={icon}>
        <Popup>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Имя:</b>
                </td>
                <td>{point.TPName}</td>
              </tr>
              <tr>
                <td>
                  <b>Превышение:</b>
                </td>
                <td>{point.alt} м</td>
              </tr>
              <tr>
                <td>
                  <b>Радиус:</b>
                </td>
                <td>{point.TPRadius > 1000 ? point.TPRadius / 1000 + ' км' : point.TPRadius + ' м'}</td>
              </tr>
              {!!+point.legDistance && (
                <tr>
                  <td>
                    <b>Предыдущий отрезок:</b>
                  </td>
                  <td>{point.legDistance} км</td>
                </tr>
              )}
              {!!+point.nextTpDist && (
                <tr>
                  <td>
                    <b>Следующий отрезок:</b>
                  </td>
                  <td>{point.nextTpDist} км</td>
                </tr>
              )}
            </tbody>
          </table>
        </Popup>
      </Marker>
    );

    if (point.TPAngle === 360) {
      ppmAreas.push(
        <Circle
          key={idx}
          center={[point.lat, point.lon]}
          radius={point.TPRadius}
          pathOptions={{ color: 'red', weight: 2, fillColor: 'green', fillOpacity: 0.3 }}
          interactive={false}
        />
      );
    } else {
      ppmAreas.push(
        <SemiCircle
          key={idx}
          center={[point.lat, point.lon]}
          color="red"
          fillColor="green"
          fillOpacity="0.3"
          direction={point.TPOuterAngle + 180}
          angle={point.TPAngle}
          radius={point.TPRadius}
          interactive={false}
        />
      );
    }
    return;
  });

  const taskLines = currentTask.turnPoints.map((point) => {
    return [point.lat, point.lon];
  });

  settingsStore.showWeatherZones &&
    currentTask.weatherZones &&
    currentTask.weatherZones.forEach((zone, idx) => {
      if (idx > 0) {
        const zoneLabel = new L.divIcon({
          html: `
          <div style="display: flex; justify-content: center;">
            <b style="
              position: absolute;
              border: 0px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 12px;
              padding-left: 0px;
              padding-right: 0px;
              top: 0px;
              text-align: center;
              color: blue;
              padding-left: 2px;
              padding-right: 2px;
            ">${zone.Name}</b>
          </div>
        `,
          iconSize: [10, 10],
          className: 'glider-icon-cn'
        });
        const positions = zone.points.map((point) => {
          return [point.lat, point.lon];
        });
        let zoneCenterLat = 0;
        let zoneCenterLon = 0;
        zone.points.forEach((point) => {
          zoneCenterLat += point.lat;
          zoneCenterLon += point.lon;
        });
        zoneCenterLat /= zone.points.length;
        zoneCenterLon /= zone.points.length;
        weatherZones.push(<Polygon key={'wz' + idx} color="blue" positions={positions} interactive={false} />);
        weatherZones.push(
          <Marker key={'fzm' + idx} position={[zoneCenterLat, zoneCenterLon]} icon={zoneLabel}>
            <Popup>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Имя:</b>
                    </td>
                    <td>{zone.Name}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Ветер:</b>
                    </td>
                    <td>
                      {zone.WindDir.toFixed(0)}° ({windSpeed(zone.WindSpeed)})
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Температура:</b>
                    </td>
                    <td>{zone.ThermalsTemp.toFixed(1)}°C</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Вер.переразвития:</b>
                    </td>
                    <td>{(zone.ThermalsOverdevelopment * 100).toFixed(1)}%</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Смещение зоны:</b>
                    </td>
                    <td>
                      {zone.MoveDir.toFixed(0)}° ({windSpeed(zone.MoveSpeed)})
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Граница:</b>
                    </td>
                    <td>{zone.BorderWidth} м</td>
                  </tr>
                </tbody>
              </table>
            </Popup>
          </Marker>
        );
      }
    });

  settingsStore.showPenaltyZones &&
    currentTask.penaltyZones &&
    currentTask.penaltyZones.forEach((zone, idx) => {
      let label = '';
      if (zone.AN !== undefined) label = zone.AN;
      else if (zone.AL !== undefined) label = zone.AL;
      else if (zone.AH !== undefined) label += zone.AH;

      const zoneLabel = new L.divIcon({
        html: `
          <div style="display: flex; justify-content: center;">
            <b style="
              position: absolute;
              border: 0px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 12px;
              padding-left: 0px;
              padding-right: 0px;
              top: 0px;
              text-align: center;
              background-color: yellow;
              padding-left: 2px;
              padding-right: 2px;
            ">${label}</b>
          </div>
        `,
        iconSize: [10, 10],
        className: 'glider-icon-cn'
      });
      const positions = zone.points.map((point) => {
        return [point.lat, point.lon];
      });
      let fzCenterLat = 0;
      let fzCenterLon = 0;
      zone.points.forEach((point) => {
        fzCenterLat += point.lat;
        fzCenterLon += point.lon;
      });
      fzCenterLat /= zone.points.length;
      fzCenterLon /= zone.points.length;
      penaltyZones.push(<Polygon key={'fzp' + idx} color="yellow" positions={positions} interactive={false} />);
      penaltyZones.push(
        <Marker key={'fzm' + idx} position={[fzCenterLat, fzCenterLon]} icon={zoneLabel}>
          <Popup>
            <table>
              <tbody>
                {zone.AC !== undefined && (
                  <tr>
                    <td>
                      <b>AC:</b>
                    </td>
                    <td>{zone.AC}</td>
                  </tr>
                )}
                {zone.AN !== undefined && (
                  <tr>
                    <td>
                      <b>AN:</b>
                    </td>
                    <td>{zone.AN}</td>
                  </tr>
                )}
                {zone.AL !== undefined && (
                  <tr>
                    <td>
                      <b>AL:</b>
                    </td>
                    <td>{zone.AL}</td>
                  </tr>
                )}
                {zone.AH !== undefined && (
                  <tr>
                    <td>
                      <b>AH:</b>
                    </td>
                    <td>{zone.AH}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Popup>
        </Marker>
      );
    });

  return (
    <LayerGroup>
      {ppmLabels}
      {ppmAreas}
      {penaltyZones}
      {weatherZones}
      <Polyline pathOptions={{ color: 'blue', weight: 2 }} positions={taskLines} interactive={false} />
      {currentTask.raceType != 'FF' && (
        <div
          style={{
            bottom: `80px`,
            right: `15px`,
            position: `fixed`,
            zIndex: `500`,
            opacity: `0.8`,
            transform: `rotate(${windDir}deg)`
          }}>
          <img title={windSpeedTitle} alt={windDir} src="/strelka2.png" width="40" height="60" />
        </div>
      )}
    </LayerGroup>
  );
}

export default observer(Task);
